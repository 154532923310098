import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";

import { Query, SanityVillage } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import VillageSearchWrapper from "@components/search/villageSearchWrapper";
import VillageHits from "@components/village/villageHits";
import VillageFilters from "@components/village/villageFilters";
import { Configure } from "react-instantsearch-dom";
import {
  PageContentWrapper,
  StickyWrapper,
  VillageHitsWrapper,
} from "@styles/villageTemplateStyles";

interface PageData extends Query {
  livingOptionVillages: { nodes: SanityVillage[] };
  careOptionVillages: { nodes: SanityVillage[] };
}
interface Props extends PageProps {
  data: PageData;
  pageContext: { isNewDevelopment?: boolean };
}

export default function VillagesLandingTemplate({
  data: {
    sanityFilteredVillagesLanding,
    sanityHeader,
    livingOptionVillages: { nodes: livingOptionVillageNodes },
    careOptionVillages: { nodes: careOptionVillageNodes },
  },
  pageContext,
}: Props) {
  if (sanityFilteredVillagesLanding == null)
    return <ErrorMsg data={sanityFilteredVillagesLanding} msg="Error fetching data for page" />;
  const [hitsLength, setHitsLength] = useState(0);
  const { isNewDevelopment } = pageContext;
  if (sanityFilteredVillagesLanding == null) return null;

  const { slug, _type, pageColour, title, seo, blocks, defaultVillageFilters } =
    sanityFilteredVillagesLanding;
  usePageMeta(pageColour?.colour?.hex, _type);
  const nodes = livingOptionVillageNodes.length ? livingOptionVillageNodes : careOptionVillageNodes;

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <VillageSearchWrapper
        indexName="villages"
        title={title}
        hideMapButton
        config={
          <Configure
            hitsPerPage={1000}
            filters={
              defaultVillageFilters
                ? `filters.${defaultVillageFilters.filterType}.title: "${defaultVillageFilters.title}"`
                : ""
            }
          />
        }
      >
        <PageContentWrapper>
          <StickyWrapper>
            <VillageFilters
              hitsLength={hitsLength}
              defaultLength={nodes.length}
              isNewDevelopment={isNewDevelopment}
            />
          </StickyWrapper>
          <VillageHitsWrapper width="70%" tabletWidth="100%" backgroundColor="white">
            <VillageHits defaultData={nodes} setHitsLength={setHitsLength} />
          </VillageHitsWrapper>
        </PageContentWrapper>
      </VillageSearchWrapper>
      {blocks && <Blocks data={blocks} />}
    </div>
  );
}

export const query = graphql`
  query filteredVillagesLandingQuery($slug: String, $filterId: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityFilteredVillagesLanding(slug: { current: { eq: $slug } }) {
      _type
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
      defaultVillageFilters {
        title
        subFilter
        filterType
        _id
        _key
        _type
      }
    }
    livingOptionVillages: allSanityVillage(
      filter: { filters: { livingOptions: { elemMatch: { _id: { eq: $filterId } } } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...sanityVillageMeta
      }
    }

    careOptionVillages: allSanityVillage(
      filter: { filters: { careOptions: { elemMatch: { _id: { eq: $filterId } } } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...sanityVillageMeta
      }
    }
  }
`;
